<template>
  <section>
    <PageMetadata />
    <h1 v-if="locationDetails.page_h1 != ''" class="header text-center pt-80 bg-primary-dark text-white" :class="locationDetails.h1_classes">{{ locationDetails.page_h1 }}</h1>
    <div id="page_content" class="mt-10 content-wrapper">
      <div class="mt-5 mb-0 mx-0" v-html="this.locationDetails.description"></div>
      <p v-if="locationDetails.phone != '' && locationDetails.phone != null" class="text-center content-p location-sub-header mb-5 no-letter-spacing">
        {{ locationDetails.phone }}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {{ locationDetails.address }} {{ locationDetails.city }}, {{ locationDetails.state_code }} {{ locationDetails.zip_code }}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        Manager:&nbsp;
        <template v-if="locationDetails.manager_name != '' && locationDetails.manager_name != null">{{ locationDetails.manager_name }}</template>
        <template v-else>TBA</template>
      </p>
      <div class="text-center"><a class="btn btn-theme location-btn" href="/contact-us">CONTACT US</a></div>
      <template v-if="hours.length > 0 || (locationDetails.hour_description != '' && locationDetails.hour_description != null)">
        <hr  class="my-5">
        <h2 class="text-center sub-header">HOURS</h2>
        <template v-for="(currentHours, currentDay, index) in hours" :key="index">
          <p class="text-center content-p location-sub-header mb-0 no-letter-spacing">
            <span class="text-capitalize">{{ currentDay }}:</span>&nbsp;{{ currentHours }}
          </p>
        </template>
        <div v-if="holidayHours.length > 0" class="my-4"></div>
        <template v-for="(currentDay, index) in holidayHours" :key="index">
          <p class="text-center content-p location-sub-header mb-0 no-letter-spacing">
            <span class="text-capitalize">{{ currentDay.holiday_date_formatted }}:</span>&nbsp;
            <template v-if="currentDay.closed">Closed</template>
            <template v-else>{{ currentDay.open_time_formatted }} to {{ currentDay.close_time_formatted }}</template>
          </p>
        </template>
        <div v-if="locationDetails.hour_description != '' && locationDetails.hour_description != null" v-html="locationDetails.hour_description" class="mt-4"></div>
      </template>
      <template v-if="socialMedias.length > 0">
        <hr class="my-5">
        <h2 class="text-center sub-header">FOLLOW US ON INSTAGRAM</h2>
        <div class="text-center">
          <a v-for="(socialMedia, index) in socialMedias" :key="index" class="btn btn-theme location-btn" :href="socialMedia.url" target="_blank">{{ socialMedia.label }}</a>
        </div>
      </template>
      <template v-if="testimonials.length > 0">
        <hr class="my-5">
        <h2 class="text-center sub-header">TESTIMONIALS</h2>
        <div class="row justify-content-center testimonials">
          <div v-for="(currentTestimonial, index) in testimonials" :key="index" class="col-lg-6 col-xl-4 single-testimonial">
            <h3 class="text-center mb-2" style="min-height: 25px;">{{ currentTestimonial.customer_name }}</h3>
            <p class="text-center testimonial-sub-header testimonial-customer" style="min-height: 24px;">{{ currentTestimonial.company_name }}</p>
            <p class="text-center testimonial-sub-header" style="min-height: 24px;">{{ currentTestimonial.city }}, {{ currentTestimonial.state.code }}</p>
            <div class="testimonial-detail">
              <p class="testimonial-p" v-html="currentTestimonial.description"></p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
import { mapMutations } from "vuex";
import { PageMetadata } from "@/components";

export default {
  name: "LocationPage",
  mixins: [misc],
  components: { PageMetadata },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      route: window.location.hash,
      locationDetails: {},
      hours: {},
      holidayHours: {},
      testimonials: {},
      socialMedias: {},
    };
  },
  mounted() {
    this.fetchDetails();
    localStorage.setItem("currentPage", this.slug);
    this.fetchMetaDetails();
  },
  methods: {
    ...mapMutations(["updateDynamicBreadcrumb"]),
    fetchDetails() {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getPageContent`,
          {
            slug: this.slug,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.locationDetails = response.data.content;
          if (this.locationDetails.current_hours.length > 0) {
            this.hours = this.locationDetails.current_hours[0].days;
          }
          this.holidayHours = this.locationDetails.current_holiday_hours;
          this.testimonials = this.locationDetails.active_testimonials;
          this.socialMedias = this.locationDetails.social_medias;
          
          const crumb = [
            {
              routeName: "Home",
              text: '<i class="fa-solid fa-house-chimney"></i>',
            },
            {
              // routeName: "Locations",
              route: "locations",
              text: 'Locations',
            },
            {
              route: this.$route.path,
              text: this.locationDetails.name
            },
          ];
          this.crumb = crumb
          this.updateDynamicBreadcrumb(this.crumb);
          this.scrollToElement();
        });
    },

    scrollToElement() {
      if (this.$route.hash) {
        setTimeout(() => {
          const elem = document.querySelector(this.$route.hash);
          if (elem) {
            document.getElementById(elem.id).scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "start",
            });
          }
        }, 50);
      }
    },
  },
  watch: {
    slug() {
      this.fetchDetails();
    },
    $route() {
      // react to route changes...
      this.scrollToElement();
    },
  },
};
</script>
<style>
#page_content {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 30px;
  color: #231f20;
}

.mt-10 {
  margin-top: 10px;
}

#buying-options,
#destination-events,
#additional-tools,
#mayesh-branch,
#mayesh-market,
.scrollElement {
  scroll-margin-top: 80px;
}
@media screen and (max-width: 480px) {
  .content-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
