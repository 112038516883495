<template>
  <section>
    <PageMetadata />
    <section class="mt-4">
      <div class="container">
        <vue-headful :title="getMetaDetails.meta_title" :description="getMetaDetails.meta_description" :keywords="getMetaDetails.meta_keywords" />
        <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
          <span v-html="flashAlert.msg"></span>
        </flash-alert>
        <div class="mb-5">
          <!-- <h1>{{ getMetaDetails.page_h1 }}</h1> -->
          <component :is="'style'" v-if="getMetaDetails.additional_style != null && getMetaDetails.additional_style != ''">
            {{ getMetaDetails.additional_style }}
          </component>
          <div class="content_below_heading" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
        </div>
      </div>
    </section>
    <section class="" id="eventplanner-page">
      <template v-if="locations.length >= 1">
        <div class="container">
          <div class="sjb-filters">
            <div class="row pb-3">
              <!-- Keywords Search-->
              <div class="sjb-search-keywords col-md-5 py-3">
                <div class="form-group">
                  <label class="sr-only" for="keywords">Keywords</label>
                  <input type="text" class="form-control" placeholder="Keywords" id="keywords" @keydown.enter="getJobs" name="search_keywords" v-model="filters.criteria.search" />
                </div>
              </div>
              <!-- Job Location Filter-->
              <div class="sjb-search-location col-md-5 py-3">
                <div class="form-group">
                  <select v-model="filters.criteria.filter_location" id="location" class="form-control">
                    <option class="level-0" value="">Location</option>
                    <option class="level-0" :value="location.city+'-'+location.state" v-for="(location, key) in locations" :key="key">{{ location.city }}, {{ location.state }}</option>
                  </select>
                </div>
              </div>
              <div class="sjb-search-button col-md-2 py-3 text-center text-md-start">
                <button class="btn-search btn btn-primary btn-theme" style="margin-left: .25rem !important;" id="btn-search" @click="getJobs"><i class="fa-solid fa-magnifying-glass" aria-hidden="true"></i></button>
                <button class="btn-search btn btn-primary btn-theme" style="margin-left: .25rem !important;" id="btn-reset" @click="resetFilterJobs"><i class="fa-solid fa-rotate-right" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="jobs.length >= 1">
          <pagination-row :recordsCount="totalJobs" :perPage="filters.perPage" :currPage="filters.pageNumb" @pageChange="changePage" />
          <div id="selected-filters-list-top" data-v-7da28470=""></div>
          <div class="list-data" v-for="(job, index) in jobs" :key="index">
            <!-- Jobs List view header -->
            <header>
              <div class="row">
                <div class="col-md-12 col-sm-11">
                  <div class="row">
                    <div class="col-md-9 pl-4">
                      <div class="job-info">
                        <h4 class="text-md-start text-center">
                          <a :href="`${job.url}`" target="_blank"><span class="job-title">{{ job.title }}</span></a>
                        </h4>
                      </div>
                    </div>
                    <!-- Start Job's Location -->
                    <div class="col-md-3 col-sm-4 col-xs-12">
                      <div class="job-location text-md-start text-center pb-md-0 pb-2">
                        <i class="fa-solid fa-location-pin"></i> {{ job.city }}, {{ job.state }}
                        <span v-if="job.branch_name"><br />({{ job.branch_name }})</span>
                      </div>
                    </div>
                    <!-- End Job's Location -->
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div id="selected-filters-list-top" data-v-7da28470=""></div>
          <pagination-row :recordsCount="totalJobs" :perPage="filters.perPage" :currPage="filters.pageNumb" @pageChange="changePage" />
        </div>
        <div v-else class="container">
          <p class="text-center py-5 fw-bold">No results found.</p>
        </div>
      </template>
      <div v-else class="container">
        <p class="text-center py-5 fw-bold">Sorry, currently we don't have any job positions available.</p>
      </div>
    </section>
    <div id="page_content_below" class="mt-10" v-if="!!getMetaDetails.description_below_dynamic" v-html="getMetaDetails.description_below_dynamic"></div>
  </section>
</template>
<script>
import { misc } from "@/mixins";
import { PaginationRow, PageMetadata } from "@/components";
import axios from "axios";

export default {
  name: "JobsPage",
  mixins: [misc],
  data() {
    return {
      isLoading: false,
      loader: "bars",
      jobs: [],
      programSettings: {},
      jobsCount: 0,
      locations: [],
      categories: [],
      totalJobs: 0,
      search: "",
      filters: {
        perPage: this.getPerPage(),
        sortBy: this.getSortBy(),
        pageNumb: 1,
        tmpDate: "",
        date: "",
        criteria: {
          search: "",
          filter_location: "",
        },
      },
    };
  },
  components: {
    PaginationRow,
    PageMetadata,
  },
  computed: {
    apiBody() {
      return {
        perPage: this.filters.perPage,
        sortBy: this.filters.sortBy,
        pageNumb: this.filters.pageNumb,
        criteria: this.filters.criteria,
      };
    },
  },
  mounted() {
    localStorage.setItem("currentPage", "careers");
    this.getJobs();
    this.getFilters();
    this.fetchMetaDetails();
  },
  methods: {
    getPerPage() {
      let val = 10;
      return parseInt(val, 10);
    },
    getSortBy() {
      if (localStorage.getItem("sortBy")) {
        return localStorage.getItem("sortBy");
      } else {
        return "Name-ASC";
      }
    },
    changePage(pageNumb) {
      this.filters.pageNumb = pageNumb;
    },
    getJobs() {
      this.isLoading = true;
      let apiUrl = process.env.VUE_APP_API_BASE + "auth/getJobs";
      axios
        .post(
          apiUrl,
          {
            filters: this.apiBody,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.jobs = response.data.jobs;
          this.totalJobs = response.data.total;
          this.isLoading = false;
        });
      this.isLoading = false;
    },
    getFilters() {
      let apiUrl = process.env.VUE_APP_API_BASE + "auth/getJobsFilters";
      axios
        .post(
          apiUrl,
          {},
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.locations = response.data.params.filters.locations.filter_locations;
        });
    },
    resetFilterJobs() {
      this.filters.criteria.search = "";
      this.filters.criteria.filter_location = "";
      this.getJobs();
    },
  },

  watch: {
    "filters.pageNumb": function () {
      this.getJobs();
    },
  },
};
</script>
<style scoped>
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#btn-search:hover, #btn-reset:hover {
  background: #f37f62;
  color: #fff;
}
#btn-search, #btn-reset {
  display: inline-block;
  vertical-align: top;
  border-radius: 0px;
  height: 40px;
  line-height: 24px;
  color: #f37f62;
  border: 2px solid #f37e62;
  font-size: 17px;
  width: 80px;
  margin: 1px 1px 0 4px;
}
.sjb-filters {
  background-color: #f2f2f2;
  border: 0;
  margin: 30px 0 15px;
  padding: 35px 30px 0;
  /* overflow: hidden; */
}
.list-data {
  background: #fff;
  border: 1px solid #e1e1e1;
  margin: 15px 0;
  padding: 20px 20px 10px;
  width: 100%;
}

.list-data a {
  text-decoration: none;
  color: #3b3a3c;
  font-size: 16px;
  word-wrap: break-word;
}
</style>